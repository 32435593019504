import React, { createContext, useContext, useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";

const SalesContext = createContext({});

export const SalesProvider = ({ children }) => {
  const apiKey = process.env.REACT_APP_SHEET_KEY;
  const spreadsheetID = process.env.REACT_APP_SPREADSHEET_ID;
  const subregionSheetID = "272498460";
  const segmentSheetID = "1207154374";
  const doc = new GoogleSpreadsheet(spreadsheetID);

  console.log(process.env.REACT_APP_SPREADSHEET_ID);

  useEffect(() => getSheets(), []);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Sales by Sub Region values
  const [subregionAnnual, setSubregionAnnual] = useState(null);
  const [subregionQ4, setSubregionQ4] = useState(null);
  const [subregionMonth, setSubregionMonth] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [ssaSeries, setSsaSeries] = useState(null);
  const [saSeries, setSaSeries] = useState(null);

  // Sales by Segment values
  const [annualPieSeries, setAnnualPieSeries] = useState(null);
  const [q4PieSeries, setQ4PieSeries] = useState(null);
  const [monthPieSeries, setMonthPieSeries] = useState(null);

  const [loading, setLoading] = useState(true);
  const [piesLoading, setPiesLoading] = useState(true);

  const getSheets = async () => {
    try {
      doc.useApiKey(apiKey);
      await doc.loadInfo();
      const subregionSheet = doc.sheetsById[subregionSheetID];
      const segmentSheet = doc.sheetsById[segmentSheetID];
      const subregionRows = await subregionSheet.getRows();
      const segmentRows = await segmentSheet.getRows();
      sortSubregionSheetData(subregionRows);
      getYearSales(subregionRows);
      getSegments(segmentRows);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (annualPieSeries && q4PieSeries && monthPieSeries) {
      setPiesLoading(false);
    }
  }, [annualPieSeries, q4PieSeries, monthPieSeries]);

  const getYearSales = (rows) => {
    const ssaYearSales = months.map((m) =>
      rows.find((r) => r.Period === m && r.Type === "SSA")
    );
    const saYearSales = months.map((m) =>
      rows.find((r) => r.Period === m && r.Type === "SA")
    );
    console.log(ssaYearSales);

    setSsaSeries([
      {
        name: "Sales by Subregion",
        data: ssaYearSales.map((m) => {
          if (m.Actual) {
            return parseInt(m.Actual.trim().replace(",", ""));
          } else {
            return null;
          }
        }),
      },
    ]);

    setSaSeries([
      {
        name: "Sales by Subregion",
        data: saYearSales.map((m) => {
          if (m.Actual) {
            return parseInt(m.Actual.trim().replace(",", ""));
          } else {
            return null;
          }
        }),
      },
    ]);
  };

  const getSegments = (rows) => {
    const annual = rows.find((x) => x.Period === "2021");
    const q4 = rows.find((x) => x.Period === "Q4");
    const october = rows.find((x) => x.Period === "October");
    console.log(annual.Recon.replace(",", ""));
    setAnnualPieSeries([
      parseInt(annual.Recon.replace(",", "")),
      parseInt(annual.SET.replace(",", "")),
      parseInt(annual.SpineCMFT.replace(",", "")),
    ]);
    setQ4PieSeries([
      parseInt(q4.Recon.replace(",", "")),
      parseInt(q4.SET.replace(",", "")),
      parseInt(q4.SpineCMFT.replace(",", "")),
    ]);
    setMonthPieSeries([
      parseInt(october.Recon.replace(",", "")),
      parseInt(october.SET.replace(",", "")),
      parseInt(october.SpineCMFT.replace(",", "")),
    ]);
  };

  const sortSubregionSheetData = (rows) => {
    setSubregionAnnual(rows.slice(0, 2));
    setSubregionQ4(rows.slice(2, 4));
    setSubregionMonth(rows.slice(8, 10));
  };

  return (
    <SalesContext.Provider
      value={{
        subregionAnnual,
        subregionQ4,
        subregionMonth,
        activeTab,
        setActiveTab,
        saSeries,
        ssaSeries,
        annualPieSeries,
        q4PieSeries,
        monthPieSeries,
        loading,
        getSheets,
        setLoading,
        piesLoading,
        setPiesLoading,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export const SalesConsumer = SalesContext.Consumer;

export const useSales = () => useContext(SalesContext);
