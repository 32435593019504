import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useSales } from "../../app/context/sales";

export default function SalesLineChart() {
  const { ssaSeries, saSeries, activeTab } = useSales();

  // Line Chart Setup
  const [lineOptions, setChartOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    yaxis: {
      show: true,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    annotations: {
      yaxis: [
        {
          y: 3913,
          borderColor: "#FF7A00",
          label: {
            borderColor: "#FF7A00",
            style: {
              color: "#fff",
              background: "#FF7A00",
            },
            text: "Q4 Target",
          },
        },
        {
          y: 3713,
          borderColor: "#FF7A00",
          label: {
            borderColor: "#FF7A00",
            style: {
              color: "#fff",
              background: "#FF7A00",
            },
            text: "Q3 Target",
          },
        },
        {
          y: 3513,
          borderColor: "#FF7A00",
          label: {
            borderColor: "#FF7A00",
            style: {
              color: "#fff",
              background: "#FF7A00",
            },
            text: "Q2 Target",
          },
        },
        {
          y: 3313,
          borderColor: "#FF7A00",
          label: {
            borderColor: "#FF7A00",
            style: {
              color: "#fff",
              background: "#FF7A00",
            },
            text: "Q1 Target",
          },
        },
      ],
    },
    dataLabels: {
      enabled: true,
    },
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
  });

  return (
    <Chart
      options={lineOptions}
      height="350"
      series={activeTab === 0 ? ssaSeries : saSeries}
      type="line"
    />
  );
}
