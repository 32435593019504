import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import { useFirebase } from "../Firebase";
import { transformDocSnap } from "../helpers";
import { isSameDay } from "date-fns";

const AuthContext = createContext();
const UserContext = createContext();

export const AuthProvider = ({ children }) => {
  const firebase = useFirebase();
  //const [loading, setLoading] = useState(true);
  const [authState, setAuthState] = useState({
    user: undefined,
    userId: undefined,
    claims: undefined,
  });
  const [userState, setUserState] = useState();

  const [permissions, setPermissions] = useState();
  const [allowed, setAllowed] = useState();

  const handleLoginRedirect = async (authUser) => {
    if (
      !authUser &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/register"
    ) {
      window.location.replace("/");
    }
  };

  const handleDashboardRedirect = useCallback(() => {
    if (
      authState.claims &&
      (window.location.pathname === "/" ||
        window.location.pathname === "/register")
    ) {
      window.location.replace("/dashboard");
    }
  }, [authState]);

  useEffect(() => {
    firebase.checkUserAuth(async (authUser) => {
      handleLoginRedirect(authUser);
      if (authUser) {
        await firebase.auth.currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            setAuthState((prev) => ({
              ...prev,
              claims: idTokenResult.claims,
            }));
          });

        setAuthState((prev) => ({
          ...prev,
          user: authUser,
          userId: authUser.uid,
        }));
      }
    });
  }, [firebase]);
  //TODO: make match shop
  useEffect(() => {
    const addLogin = async () => {
      const today = new Date();
      const batch = firebase.db.batch();

      await batch.update(firebase.user(userState.id), {
        lastLoginSales: new Date(),
      });

      //Increment login count
      await batch.set(
        firebase.setting("salesLogins"),
        {
          [`${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`]:
            firebase.firestore.FieldValue.arrayUnion(userState.id),
        },
        { merge: true }
      );

      return await batch.commit();
    };
    if (
      (userState && !userState?.lastLoginSales) ||
      (userState?.lastLoginSales &&
        !isSameDay(userState?.lastLoginSales.toDate(), new Date()))
    ) {
      addLogin();
    }
  }, [firebase, userState]);

  useEffect(() => {
    const unsubscribe = authState.userId
      ? firebase
          .user(authState.userId)
          .onSnapshot((snap) => setUserState(transformDocSnap(snap)))
      : () => console.log("waiting for auth");

    return () => unsubscribe();
  }, [authState, firebase]);

  useEffect(() => {
    const unsubscribe = authState.userId
      ? firebase
          .setting("salesPermissions")
          .onSnapshot((snap) => setPermissions(transformDocSnap(snap)))
      : () => console.log("waiting for auth");

    return () => unsubscribe();
  }, [authState, firebase]);

  useEffect(() => {
    if (userState && permissions) {
      if (
        permissions.allowAll ||
        permissions?.department
          ?.map((d) => d.value)
          ?.includes(userState.department) ||
        permissions?.users
          ?.map((u) => u.value)
          ?.includes(`${userState.id}__${userState.displayName}`)
      ) {
        setAllowed(true);
        handleDashboardRedirect();
      } else {
        if (window.location.pathname !== "/no-access") {
          window.location.replace("/no-access");
        }
      }
    }
  }, [userState, permissions, handleDashboardRedirect]);

  return (
    <UserContext.Provider value={userState}>
      <AuthContext.Provider value={{ ...authState, allowed }}>
        {children}
      </AuthContext.Provider>
    </UserContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
export const useUserContext = () => useContext(UserContext);
