import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../features/auth/Login";
import { useAuthContext } from "./context/auth";
import Error404 from "./Error404";
import ForgotPassword from "../features/auth/ForgotPassword";
import { useEffect } from "react";
import NoAccess from "../features/auth/NoAccess";
import { useMenu } from "./context/menu";
import Dashboard from "../features/Dashboard/";

const Wrapper = ({ location, children }) => {
  const { setShowAdminBar } = useMenu();
  useEffect(() => {
    setShowAdminBar(location?.pathname?.includes("admin-area"));
  }, [location, setShowAdminBar]);
  return children;
};

export default function Router() {
  const auth = useAuthContext();
  const authRoutes = [
    { path: "/dashboard", component: (props) => <Dashboard {...props} /> },
  ];
  const notApprovedRoutes = [];

  const adminRoutes = [];

  const createRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        exact
        path={route.path}
        render={(props) => {
          return <Wrapper {...props}>{route.component(props)}</Wrapper>;
        }}
        key={idx}
      />
    ));
  };

  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
      {auth?.allowed &&
        auth?.claims?.isAdmin &&
        auth?.claims?.isApproved &&
        !auth?.claims?.firstLogin &&
        createRoutes(adminRoutes)}
      {auth?.allowed &&
        auth?.claims?.isApproved &&
        !auth?.claims?.firstLogin &&
        createRoutes(authRoutes)}

      {auth?.user && (
        <Route exact path="/no-access" render={() => <NoAccess />} />
      )}
      {(!auth?.claims?.isApproved || auth?.claims?.firstLogin) &&
        createRoutes(notApprovedRoutes)}
      {auth?.user && auth?.allowed !== undefined && (
        <Route
          exact
          render={(props) => (
            <Wrapper {...props}>
              <Error404 />
            </Wrapper>
          )}
        />
      )}
    </Switch>
  );
}
