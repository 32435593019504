import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "@theme-ui/components";
import { useSales } from "../../app/context/sales";

export default function SalesCards() {
  const { subregionAnnual, subregionQ4, subregionMonth, activeTab } =
    useSales();

  return (
    <CardContainer>
      {/* Annual Sales */}
      <SalesCard>
        <div class="actual">
          <h2>Annual Sales</h2>
          <ActualNumbers>
            <h1>{subregionAnnual[activeTab].Actual}</h1>
            <h3>{"/" + subregionAnnual[activeTab].Goal}</h3>
          </ActualNumbers>
        </div>
        <div class="remaining">
          <h2>Remaining</h2>
          <div class="spacer"></div>
          <h3>{subregionAnnual[activeTab].Remaining}</h3>
        </div>
        <div class="growth">
          <SmallNumbers>
            <h4>Growth</h4>
            <div class="growthPercentage">
              <h3
                style={
                  subregionAnnual[activeTab].Growth.substring(1, 0) === "-"
                    ? { color: "#ED2626" }
                    : { color: "#8ABD3D" }
                }
              >
                {subregionAnnual[activeTab].Growth.substring(1, 0) === "-"
                  ? subregionAnnual[activeTab].Growth.substring(1)
                  : subregionAnnual[activeTab].Growth}
              </h3>
              {subregionAnnual[activeTab].Growth.substring(1, 0) === "-" ? (
                <FontAwesomeIcon
                  icon={["fas", "caret-down"]}
                  style={{ color: "#ED2626" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={["fas", "caret-up"]}
                  style={{ color: "#8ABD3D" }}
                />
              )}
            </div>
          </SmallNumbers>
        </div>
        <div class="achieved">
          <SmallNumbers>
            <h4>Achieved</h4>
            <h3>{subregionAnnual[activeTab].Achieved}</h3>
          </SmallNumbers>
        </div>
        <div class="expected">
          <SmallNumbers>
            <h4>Expected</h4>
            <h3>{subregionAnnual[activeTab].Expected}</h3>
          </SmallNumbers>
        </div>
      </SalesCard>

      {/* Quarterly Sales */}
      <SalesCard>
        <div class="actual">
          <h2>Quarterly Sales (Q4)</h2>
          <ActualNumbers>
            <h1>{subregionQ4[activeTab].Actual}</h1>
            <h3>{"/" + subregionQ4[activeTab].Goal}</h3>
          </ActualNumbers>
        </div>
        <div class="remaining">
          <h2>Remaining</h2>
          <div class="spacer"></div>
          <h3>{subregionQ4[activeTab].Remaining}</h3>
        </div>
        <div class="growth">
          <SmallNumbers>
            <h4>Growth</h4>
            <div class="growthPercentage">
              <h3
                style={
                  subregionQ4[activeTab].Growth.substring(1, 0) === "-"
                    ? { color: "#ED2626" }
                    : { color: "#8ABD3D" }
                }
              >
                {subregionQ4[activeTab].Growth.substring(1, 0) === "-"
                  ? subregionQ4[activeTab].Growth.substring(1)
                  : subregionQ4[activeTab].Growth}
              </h3>
              {subregionQ4[activeTab].Growth.substring(1, 0) === "-" ? (
                <FontAwesomeIcon
                  icon={["fas", "caret-down"]}
                  style={{ color: "#ED2626" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={["fas", "caret-up"]}
                  style={{ color: "#8ABD3D" }}
                />
              )}
            </div>
          </SmallNumbers>
        </div>
        <div class="achieved">
          <SmallNumbers>
            <h4>Achieved</h4>
            <h3>{subregionQ4[activeTab].Achieved}</h3>
          </SmallNumbers>
        </div>
        <div class="expected">
          <SmallNumbers>
            <h4>Expected</h4>
            <h3>{subregionQ4[activeTab].Expected}</h3>
          </SmallNumbers>
        </div>
      </SalesCard>

      {/* Monthly Sales */}
      <SalesCard>
        <div class="actual">
          <h2>Monthly Sales (Oct)</h2>
          <ActualNumbers>
            <h1>{subregionMonth[activeTab].Actual}</h1>
            <h3>{"/" + subregionMonth[activeTab].Goal}</h3>
          </ActualNumbers>
        </div>
        <div class="remaining">
          <h2>Remaining</h2>
          <div class="spacer"></div>
          <h3>{subregionMonth[activeTab].Remaining}</h3>
        </div>
        <div class="growth">
          <SmallNumbers>
            <h4>Growth</h4>
            <div class="growthPercentage">
              <h3
                style={
                  subregionMonth[activeTab].Growth.substring(1, 0) === "-"
                    ? { color: "#ED2626" }
                    : { color: "#8ABD3D" }
                }
              >
                {subregionMonth[activeTab].Growth.substring(1, 0) === "-"
                  ? subregionMonth[activeTab].Growth.substring(1)
                  : subregionMonth[activeTab].Growth}
              </h3>
              {subregionMonth[activeTab].Growth.substring(1, 0) === "-" ? (
                <FontAwesomeIcon
                  icon={["fas", "caret-down"]}
                  style={{ color: "#ED2626" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={["fas", "caret-up"]}
                  style={{ color: "#8ABD3D" }}
                />
              )}
            </div>
          </SmallNumbers>
        </div>
        <div class="achieved">
          <SmallNumbers>
            <h4>Achieved</h4>
            <h3>{subregionMonth[activeTab].Achieved}</h3>
          </SmallNumbers>
        </div>
        <div class="expected">
          <SmallNumbers>
            <h4>Expected</h4>
            <h3>{subregionMonth[activeTab].Expected}</h3>
          </SmallNumbers>
        </div>
      </SalesCard>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const SalesCard = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  height: 250px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-top: 5%;
  flex-grow: 1;
  padding: 0;
  grid-gap: 1px;
  grid-template-areas:
    "actual actual actual growth"
    "actual actual actual achieved"
    "remaining remaining remaining expected";

  background-color: rgb(0, 0, 0);

  @media (min-width: 1200px) {
    width: 30%;
    margin-top: 0;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 300;
    margin: 0;
  }

  .actual {
    grid-area: actual;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7%;
    margin-left: -2px;
    margin-top: -2px;
  }
  .remaining {
    grid-area: remaining;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7%;
    margin-left: -2px;
    margin-bottom: -2px;

    h3 {
      font-size: 1.25em;
      margin: 0;
      padding-right: 7%;
    }

    .spacer {
      flex-grow: 1;
    }
  }
  .growth {
    grid-area: growth;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2px;
    margin-right: -2px;

    .growthPercentage {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .achieved {
    grid-area: achieved;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: -2px;
  }
  .expected {
    grid-area: expected;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: -2px;
    margin-right: -2px;
  }
`;

const ActualNumbers = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  align-items: flex-end;

  h1 {
    font-size: 2em;
    margin: 0;
  }
  h3 {
    font-size: 1.25em;
    margin: 0;
    padding-bottom: 1%;
    padding-left: 2%;
  }
`;

const SmallNumbers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 1.25em;
    margin: 0;
    padding-top: 10%;
  }

  h4 {
    margin: 0;
    font-weight: 300;
  }
`;
