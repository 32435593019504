import React from "react";
import { Heading, Button } from "@theme-ui/components";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

export default function BlockHeaderContainer({
	title,
	link,
	actionType,
	buttonText,
	action,
	rightComponent,
}) {
	return (
		<Header actionType={rightComponent ? true : actionType}>
			<Heading>{title}</Heading>
			{rightComponent && rightComponent}
			{actionType === "link" && (
				<NavLink to={link}>
					<Button>{buttonText}</Button>
				</NavLink>
			)}
			{actionType === "button" && (
				<span>
					<Button onClick={() => action()}>{buttonText}</Button>
				</span>
			)}
		</Header>
	);
}

const Header = styled.div`
	display: grid;
	grid-template-columns: ${(props) => (props.actionType ? "1fr 1fr" : "1fr")};
	align-items: center;
	margin-bottom: 1em;
	text-transform: capitalize;
	div,
	span,
	a {
		text-align: right;
		justify-self: end;
	}
	button {
		color: #fff;
	}
`;
