import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./DSL/theme";
import "./DSL/styles/main.css";

import { FirebaseProvider } from "./context/Firebase";
import { AuthProvider } from "./context/auth";
import Router from "./Router";

import "./DSL/components/icons/library";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./context/data";
import { ToastProvider } from "react-toast-notifications";
import { Toast } from "./DSL/components/elements/Toast";
import Main from "./Main";
import ErrorBoundary from "./ErrorBoundary";
import { SalesProvider } from "./context/sales";

function App() {
  return (
    <ErrorBoundary>
      <FirebaseProvider>
        <AuthProvider>
          <SalesProvider>
            <DataProvider>
              <ThemeProvider theme={theme}>
                <ToastProvider components={{ Toast: Toast }}>
                  <BrowserRouter>
                    {window.location.pathname === "/" ||
                    window.location.pathname === "/register" ||
                    window.location.pathname === "/first-login" ? (
                      <Router />
                    ) : (
                      <Main />
                    )}
                  </BrowserRouter>
                </ToastProvider>
              </ThemeProvider>
            </DataProvider>
          </SalesProvider>
        </AuthProvider>
      </FirebaseProvider>
    </ErrorBoundary>
  );
}

export default App;
