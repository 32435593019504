/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";

import {
  Card,
  Heading,
  Flex,
  Button,
  Image,
  Label,
  Input,
  Message,
} from "@theme-ui/components";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import styled from "@emotion/styled";
import { useForm } from "../../app/forms/useForm";
import { useFirebase } from "../../app/context/Firebase";
import { NavLink } from "react-router-dom";
import { primary } from "../../app/DSL/theme";

export default function Login() {
  const formFields = [
    {
      name: "email",
      title: "Email",
      type: "email",
      validate: (x) => {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(x);
      },
      error: "Must be valid email address",
      placeholder: "example@zimmerbiomet.com",
    },
    {
      name: "password",
      title: "Password",
      type: "password",
      validate: (x) => x.length >= 6,
      error: "Password must be 6 characters or longer",
      placeholder: "••••••",
    },
  ];
  const { formValid, inputProps, formattedValues, values } =
    useForm(formFields);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const firebase = useFirebase();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formValues = formattedValues();
    setLoading(true);
    firebase
      .loginWithEmail(formValues.email, formValues.password)
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  };

  return (
    <LoginBox>
      <LoginCard sx={{ textAlign: "center" }}>
        <LoginForm onSubmit={handleSubmit}>
          <div>
            <Image src="/assets/sales.svg" variant="login" />
            <Heading as="h1" variant="display">
              {process.env.REACT_APP_SITE_TITLE.toUpperCase()}
            </Heading>
          </div>
          <Heading as="h4" color="blue.font.500">
            LOGIN
          </Heading>
          <div>
            {formFields.map(({ title, name, placeholder }, idx) => (
              <span key={idx}>
                <InputWrap
                  state={
                    (error || values[name].touched) &&
                    !values[name].valid &&
                    values[name].blur &&
                    "error"
                  }
                >
                  <Label htmlFor={name}>{title}</Label>
                  <Input {...inputProps(name)} placeholder={placeholder} />
                  {(error || values[name].touched) &&
                  !values[name].valid &&
                  values[name].blur ? (
                    <Label variant="infoLabel.warning">
                      {values[name].error}
                    </Label>
                  ) : (
                    <Label variant="infoLabel.hidden">...</Label>
                  )}
                </InputWrap>
              </span>
            ))}
            {error && <Message variant="warning">{error.message}</Message>}
            <small>
              By logging in you accept the{" "}
              <a
                href={process.env.REACT_APP_TERMS}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>{" "}
              related to the use of this system.
            </small>
            <Flex>
              {loading ? (
                <Button width={1 / 2} mr={2} my={4} disabled={loading}>
                  Loading
                </Button>
              ) : (
                <Button
                  width={1 / 2}
                  mr={2}
                  my={4}
                  type="submit"
                  disabled={!formValid}
                >
                  Login
                </Button>
              )}
            </Flex>
          </div>
          <div>
            <p>
              No account yet?{" "}
              <a href={`${process.env.REACT_APP_HQ_LINK}/register`}>
                Create one
              </a>
            </p>
            <NavLink to="/forgot-password">Forgot Password?</NavLink>
          </div>
        </LoginForm>
      </LoginCard>
    </LoginBox>
  );
}

const LoginBox = styled.div`
  background-image: url(${process.env.REACT_APP_BG_URL});
  background-size: ${process.env.REACT_APP_BG_SIZE};
  background-repeat: ${process.env.REACT_APP_BG_REPEAT};
  background-color: ${(props) => props.theme.colors.tusk.shade["300"]};
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: center;
`;

const LoginCard = styled(Card)`
  width: 35%;
  padding: 2em 4em;
  @media (max-width: 1024px) {
    width: 75%;
    margin-top: 3em;
    padding: 2em;

    img {
      width: 25%;
      margin-bottom: 1em;
    }
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 3em;
    padding: 2em;

    img {
      width: 40%;
      margin-bottom: 0;
    }
  }
`;

const LoginForm = styled.form`
  display: grid;
  grid-gap: 24;
  grid-template-rows: 150px 100px 1fr 50px;
  align-content: center;
  align-items: center;
  & div:first-of-type {
    align-self: end;
  }
  a {
    color: ${(props) => props.theme.colors[primary].shade["300"]};
  }

  @media (max-width: 768px) {
    grid-template-rows: 100px 100px 1fr 50px;
  }
`;
