import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import config from "./config";

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const firestore = firebase.firestore;

const Firebase = {
  /////////////////////// AUTH ///////////////////////////////
  auth,
  storage,

  loginWithEmail: (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  },
  signupWithEmail: (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  },
  signOut: () => {
    return auth.signOut();
  },
  checkUserAuth: (user) => {
    return auth.onAuthStateChanged(user);
  },

  sendSignInMail: (email, actionCodeSettings) => {
    return auth.sendSignInLinkToEmail(email, actionCodeSettings);
  },

  loginInWithLink: (email, link) => {
    return auth.signInWithEmailLink(email, link);
  },
  updatePassword: (password) => {
    return auth.currentUser.updatePassword(password);
  },

  sendForgotPasswordEmail: (email) => {
    return auth.sendPasswordResetEmail(email);
  },

  /////////////////////// FIRESTORE ///////////////////////////////
  db,
  firestore,
  //----------------- USERS -----------------

  createNewUser: (userData) => {
    return db.collection("users").doc().set(userData);
  },

  createUserWithId: (userData) => {
    return db.collection("users").doc(userData.id).set(userData);
  },

  user: (userId) => {
    return db.collection("users").doc(userId);
  },

  users: (userId) => {
    return db.collection("users");
  },

  findUserByEmail: (email) => {
    return db.collection("users").where("email", "==", email);
  },
  archiveUser: (userId) => {
    return db.collection("users").doc(userId).update({ isArchived: true });
  },

  approveUser: (userId) => {
    return db.collection("users").doc(userId).update({ isApproved: true });
  },

  featureProduct: (userId, featuredArray) => {
    return db
      .collection("users")
      .doc(userId)
      .update({ brochureFeaturedList: featuredArray });
    // .set({ ...values, featuredList: featuredArray }, { merge: true });
  },

  //----------------- HOSPITALS -----------------

  hospitals: () => {
    return db.collection("hospitals");
  },

  hospital: (hospitalId) => {
    return db.collection("hospitals").doc(hospitalId);
  },
  archiveHospital: (hospitalId) => {
    return db
      .collection("hospitals")
      .doc(hospitalId)
      .update({ isArchived: true });
  },

  //----------------- CATALOGUE -----------------

  catalogue: () => {
    return db.collection("catalogue");
  },

  product: (docId) => {
    return db.collection("catalogue").doc(docId);
  },

  archiveProduct: (docId) => {
    return db.collection("catalogue").doc(docId).update({ isArchived: true });
  },

  viewProduct: (docId) => {
    return db
      .collection("catalogue")
      .doc(docId)
      .update({ viewCount: firestore.FieldValue.increment(1) });
  },

  updateProduct: (values) => {
    return db
      .collection("catalogue")
      .doc(values.id)
      .set({ ...values, updatedAt: new Date() }, { merge: true });
  },

  //----------------- ENQUIRIES -----------------

  enquiries: () => {
    return db.collection("enquiries");
  },

  enquiry: (enquiryId) => {
    return db.collection("enquiries").doc(enquiryId);
  },

  //----------------- PRODUCT ENQUIRIES -----------------

  productEnquiries: () => {
    return db.collection("productEnquiries");
  },

  productEnquiry: (productEnquiryId) => {
    return db.collection("productEnquiries").doc(productEnquiryId);
  },

  //----------------- EVENTS -----------------

  events: () => {
    return db.collection("events");
  },

  archiveEvent: (eventId) => {
    return db.collection("events").doc(eventId).update({ isArchived: true });
  },

  //----------------- SETTINGS -----------------

  setting: (name) => {
    return db.collection("settings").doc(name);
  },
  //----------------- SLIDERS -----------------

  sliders: () => {
    return db.collection("dashboardSliders");
  },

  slider: (id) => {
    return db.collection("dashboardSliders").doc(id);
  },
};

export default Firebase;
