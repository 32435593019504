import React, { useState } from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Button, Card } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { Ring } from "react-awesome-spinners";
import SalesCards from "./SalesCards";
import { useSales } from "../../app/context/sales";
import SalesLineChart from "./SalesLineChart";
import SalesPieChart from "./SalesPieChart";

const Dashboard = () => {
  const { loading, piesLoading, activeTab, setActiveTab } = useSales();

  return (
    <div class="dashboard">
      <PageHeaderContainer>Dashboard</PageHeaderContainer>

      {/* Sales by Sub Region */}
      <Card style={{ backgroundColor: "#FFF" }}>
        <BlockHeaderContainer title={`Sales by Subregion`} />

        <FilterRow>
          {/* Buttons to switch between SA & SSA */}
          <SubRegionFilters>
            <Button
              class="filterBtn"
              variant={activeTab === 0 ? `primary` : `outline`}
              onClick={() => setActiveTab(0)}
              style={{ marginRight: "5%" }}
            >
              SA
            </Button>
            <Button
              class="filterBtn"
              variant={activeTab === 1 ? `primary` : `outline`}
              onClick={() => setActiveTab(1)}
              style={{ marginRight: "5%" }}
            >
              SSA
            </Button>
          </SubRegionFilters>
          <p>
            <i>All figures shown are in ZAR '000s</i>
          </p>
        </FilterRow>

        {/* Sales by Sub Region Cards (SA & SSA) */}
        {loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Ring color="teal" />
          </div>
        ) : (
          <SalesCards />
        )}
        <SalesGraphCard>
          <h2>Sales over the year</h2>
          {!loading ? (
            <SalesLineChart />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Ring color="teal" />
            </div>
          )}
        </SalesGraphCard>
      </Card>

      {/* Sales by Segment */}
      <Card style={{ backgroundColor: "#FFF", marginTop: "2%" }}>
        <BlockHeaderContainer title={`Sales by Segment`} />
        {!piesLoading ? (
          <SalesPieChart />
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Ring color="teal" />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Dashboard;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;

  p {
    font-size: 1em;
    flex-grow: 1;
    text-align: right;

    @media (min-width: 1200px) {
      padding-right: 5%;
    }
  }
`;

const SalesGraphCard = styled(Card)`
  width: 96%;
  margin-left: 1.5%;
  margin-top: 5%;
  background-color: #f9f9f9;
  h2 {
    font-size: 1.5em;
    font-weight: 300;
    margin: 0;
    padding-bottom: 3%;
  }
`;

const SubRegionFilters = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1.5%;
  margin-bottom: 2%;

  .filterBtn {
    padding-right: 5%;
    padding-left: 5%;
    margin-right: 10%;
  }
`;
