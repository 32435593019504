import React from "react";
import PageHeaderContainer from "./DSL/components/layout/PageHeaderContainer";
import { Image, Card, Button } from "@theme-ui/components";
import { NavLink } from "react-router-dom";

export default function Error404() {
  return (
    <div>
      <PageHeaderContainer>Page not found</PageHeaderContainer>
      <Card style={{ textAlign: "center" }}>
        <Image src="/assets/404.svg" width="30%" />
        <h3>We cannot find the page you are looking for</h3>
        <p>You do not have access to this page or the page does not exist</p>
        <NavLink to="/dashboard">
          <Button>Go back to the dashboard</Button>
        </NavLink>
      </Card>
    </div>
  );
}
