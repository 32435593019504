import "./styles/main.css";
// import "./styles/wordpress.css";
import button from "./styles/button";
import colours from "./styles/colours";
import forms from "./styles/form";

export const primary = "lightTeal";

const theme = {
  colors: colours,
  ...forms,
  styles: {
    hr: {
      color: "tusk.shade.500",
    },
  },
  backgroundColor: "white",
  fonts: {
    body: '"Source Sans Pro", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  cards: {
    primary: {
      width: "[ 1, 1, 1/2 ]",
      padding: "2em",
      borderRadius: 2,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
      bg: "white",
    },
    small: {
      width: "[ 1, 1, 1/2 ]",
      padding: "1em",
      borderRadius: 2,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
      bg: "white",
    },
    hoverable: {
      width: "[ 1, 1, 1/2 ]",
      padding: "2em",
      borderRadius: 2,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
      bg: "white",
      border: "0px solid white",
      "&:hover": {
        border: "0px solid tusk.shade.900",
        boxShadow: "1px 1px 7px rgba(0, 0, 0, .2)",
        transform: "translate(0, -2px)",
        transition: "transform ease 100ms",
      },
      cursor: "pointer",
    },
    "small-hoverable": {
      width: "[ 1, 1, 1/2 ]",
      padding: "1em",
      borderRadius: 2,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
      bg: "white",
      border: "0px solid white",
      "&:hover": {
        border: "0px solid tusk.shade.900",
        boxShadow: "1px 1px 7px rgba(0, 0, 0, .2)",
        transform: "translate(0, -2px)",
        transition: "transform ease 100ms",
      },
      cursor: "pointer",
    },
  },
  buttons: {
    primary: {
      ...button,
      backgroundColor: `${primary}.shade.500`,
      border: "2px",
      borderStyle: "solid",
      borderColor: `${primary}.shade.500`,
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: `${primary}.shade.900`,
        borderColor: `${primary}.shade.900`,
      },
    },
    secondary: {
      ...button,
      backgroundColor: "tusk.shade.500",
      color: `${primary}.shade.500`,
      border: "2px",
      borderStyle: "solid",
      borderColor: "tusk.shade.500",
    },
    outline: {
      ...button,
      backgroundColor: "transparent",
      border: "2px",
      borderStyle: "solid",
      borderColor: `${primary}.shade.500`,
      color: `${primary}.shade.500`,
      fontWeight: "bold",
      "&:hover": {
        borderColor: `${primary}.shade.900`,
        backgroundColor: `${primary}.shade.900`,
        color: `${primary}.font.900`,
      },
    },
    outlineWhite: {
      ...button,
      backgroundColor: "transparent",
      border: "2px",
      borderStyle: "solid",
      borderColor: "white",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        borderColor: "white",
        backgroundColor: "rgba(0,0,0,0.2)",
        color: "white",
      },
    },
    disabled: {
      ...button,
      backgroundColor: "tusk.shade.900",
      color: "white",
      pointerEvents: "none",
      fontWeight: "bold",
    },
    infoRequest: {
      ...button,
      backgroundColor: `${primary}.shade.500`,
      border: "2px",
      borderStyle: "solid",
      marginTop: "10px",
      borderColor: `${primary}.shade.500`,
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: `${primary}.shade.900`,
        borderColor: `${primary}.shade.900`,
      },
    },
  },
  images: {
    login: {
      width: 150,
      height: 150,
      marginBottom: "2em",
    },
    cartPlaceholder: {
      width: 150,
      height: 150,
      maxWidth: "unset",
    },
  },
  messages: {
    primary: {
      background: "transparent",
      border: `2px solid`,
      borderColor: `${primary}.shade.500`,
      color: `${primary}.shade.500`,
      h3: {
        marginTop: 0,
      },
      p: {
        marginBottom: 0,
      },
    },
    warning: {
      backgroundColor: "warning.shade.100",
      color: "warning.shade.500",
    },
  },
};

export default theme;
