import React from "react";
import { UsersProvider } from "../users";
import { MenuProvider } from "../menu";

export const DataProvider = ({ children }) => {
  return (
    <UsersProvider>
      <MenuProvider>{children}</MenuProvider>
    </UsersProvider>
  );
};
