import React, { useState } from "react";
import styled from "@emotion/styled";
import Chart from "react-apexcharts";
import { useSales } from "../../app/context/sales";

export default function SalesPieChart() {
  const { annualPieSeries, q4PieSeries, monthPieSeries } = useSales();

  // Pie Charts Setup
  const [pieOptions, setPieOptions] = useState({
    chart: {
      type: "donut",
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },

    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#0283BF", "#8ABD3D", "#FCAF18"],
    labels: ["Recon", "SET", "Spine & CMFT"],
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      pie: {
        size: "100%",
        customScale: 1,
        donut: {
          size: "50%",
          labels: {
            show: false,
            name: {
              show: false,
              fontSize: "32px",
            },
            value: {
              show: true,
              fontSize: "32px",
            },
            total: {
              show: true,
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  return (
    <PieChartsContainer>
      <ChartContainer>
        <Chart
          options={pieOptions}
          series={annualPieSeries}
          type="donut"
          height="330"
        />
        <h5>Year to date</h5>
        <PieTotal>{annualPieSeries.reduce((a, b) => a + b)}</PieTotal>
      </ChartContainer>
      <ChartContainer>
        <Chart
          options={pieOptions}
          series={q4PieSeries}
          type="donut"
          height="330"
        />
        <h5>This quarter (Q4)</h5>
        <PieTotal>{q4PieSeries.reduce((a, b) => a + b)}</PieTotal>
      </ChartContainer>
      <ChartContainer>
        <Chart
          options={pieOptions}
          series={monthPieSeries}
          type="donut"
          height="330"
        />
        <h5>This month (Oct)</h5>
        <PieTotal>{monthPieSeries.reduce((a, b) => a + b)}</PieTotal>
      </ChartContainer>
      <KeyContainer>
        <KeyLine>
          <span class="dot" style={{ backgroundColor: "#1081c4" }} />
          <h5>Recon</h5>
        </KeyLine>
        <KeyLine>
          <span class="dot" style={{ backgroundColor: "#93bc1f" }} />
          <h5>SET</h5>
        </KeyLine>
        <KeyLine>
          <span class="dot" style={{ backgroundColor: "#f9b000" }} />
          <h5>Spine & CMFT</h5>
        </KeyLine>
      </KeyContainer>
    </PieChartsContainer>
  );
}

const PieTotal = styled.p`
  position: absolute;
  padding-bottom: 20%;
  font-size: 28px;
  font-weight: 600;

  @media (max-width: 1200px) {
    padding-bottom: 8%;
  }

  @media (max-width: 600px) {
    padding-bottom: 15%;
  }
`;

const ChartContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  h5 {
    font-weight: 300;
  }
`;

const KeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
  margin-right: 5%;
  margin-left: 2%;
  margin-top: 2%;

  h5 {
    font-weight: 300;
  }

  @media (min-width: 1200px) {
    flex-direction: column;
    width: 100px;
    justify-content: flex-start;
    align-items: left;
  }
`;

const KeyLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 130px;
  align-items: center;

  @media (min-width: 1200px) {
    width: 200px;
  }

  .dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 5%;
  }

  h5 {
    padding: 0;
    margin: 5%;
  }
`;

const PieChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3%;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
